export function useSearchIndexName() {
  const runtimeConfig = useRuntimeConfig();
  const { locale } = useI18n();

  const searchIndexName = computed(() => {
    return `${runtimeConfig.public.searchIndexPrefix}${locale.value}`;
  });

  return searchIndexName;
}
