import { type ResourceType, resourceTypes } from '~/data/resourceTypes';

// TODO: Support locales other than "en"

export function getResourceType(slug: string): ResourceType {
  const resourceType = resourceTypes.find((resourceType) => resourceType.slug === slug);
  if (!resourceType) {
    // TODO: Log the error but return `{ slug, name: { en: slug }`;
    throw new Error(`Unknown resource-type slug: ${slug}`);
  }
  return resourceType;
}
