import type { InsightsMethodMap } from 'search-insights';

type MethodName = keyof InsightsMethodMap;

// Strings for internal analytics - translatin not needed
function toActionName(type: string) {
  switch (type) {
    case 'document':
      return 'Document Downloaded';
    case 'video':
      return 'Video Played';
    case 'module':
      return 'Module Opened';
    default:
      return 'Unknown Resource Conversion';
  }
}

export function useAlgoliaInsightsTracking() {
  const { $insightsClient } = useNuxtApp();
  const searchIndexName = useSearchIndexName();

  // NOTE: Magic number - the 0 specifies that this is the parameter type
  function trackAlgoliaEvent(method: MethodName, ...args: InsightsMethodMap[MethodName][0]) {
    if (!import.meta.client || !$insightsClient) {
      return;
    }

    $insightsClient(method, ...args);
  }

  function trackResourcePreviewClick(params: { id: string; type: string }) {
    const eventName = params.type === 'collection' ? 'Collection Clicked' : 'Resource Clicked';

    // After Search click events are handled automatically by vue-instantsearch - so only regular clicks need tracking
    trackAlgoliaEvent('clickedObjectIDs', {
      eventName,
      index: searchIndexName.value,
      objectIDs: [params.id],
    });
  }

  function trackResourceConversion({
    type,
    id,
    collectionId,
    queryId,
  }: {
    type: string;
    id: string;
    collectionId?: string;
    queryId?: string;
  }) {
    if (queryId) {
      trackAlgoliaEvent('convertedObjectIDsAfterSearch', {
        eventName: `${toActionName(type)} After Search`,
        index: searchIndexName.value,
        objectIDs: [id],
        queryID: queryId,
      });
    } else {
      trackAlgoliaEvent('convertedObjectIDs', {
        eventName: toActionName(type),
        index: searchIndexName.value,
        objectIDs: [id],
      });
    }

    if (collectionId) {
      trackCollectionConversion({
        id: collectionId,
        queryId,
      });
    }
  }

  function trackCollectionConversion({ id, queryId }: { id: string; queryId?: string }) {
    if (queryId) {
      trackAlgoliaEvent('convertedObjectIDsAfterSearch', {
        eventName: `Collection Used After Search`,
        index: searchIndexName.value,
        objectIDs: [id],
        queryID: queryId,
      });
    } else {
      trackAlgoliaEvent('convertedObjectIDs', {
        eventName: 'Collection Used',
        index: searchIndexName.value,
        objectIDs: [id],
      });
    }
  }

  return {
    trackAlgoliaEvent,
    trackCollectionConversion,
    trackResourceConversion,
    trackResourcePreviewClick,
  };
}
