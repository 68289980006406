<script setup lang="ts">
import { cva, type VariantProps } from 'class-variance-authority';

const chip = cva(['border', 'font-bold', 'font-lato', 'inline-block', 'py-1', 'text-xs', 'tracking-widest'], {
  variants: {
    fill: {
      dark: ['border-gray-600', 'bg-gray-600', 'text-white'],
      solid: ['border-warmGrey', 'bg-warmGrey', 'text-white'],
      outline: ['bg-white', 'border-grey-darkest', 'outline-none', 'text-grey-darkest'],
    },
    textCase: {
      normal: ['normal-case'],
      uppercase: ['uppercase'],
    },
    width: {
      normal: ['px-2'],
      narrow: ['px-1'],
    },
  },
});

type ChipProps = VariantProps<typeof chip>;

withDefaults(
  defineProps<{
    fill?: ChipProps['fill'];
    textCase?: ChipProps['textCase'];
    width?: ChipProps['width'];
  }>(),
  { fill: 'solid', textCase: 'uppercase', width: 'normal' }
);
</script>

<template>
  <!--  px-1 -->
  <div :class="chip({ fill, textCase, width })">
    <slot />
  </div>
</template>
