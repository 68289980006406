export function useFormatDuration() {
  const { t } = useI18n();

  return function (durationInMinutes: number) {
    if (!durationInMinutes) {
      return '';
    }

    const roundedDuration = Math.round(durationInMinutes);

    const durationInHrs = roundedDuration / 60;

    if (durationInHrs >= 1) {
      const roundedMinutes = roundedDuration % 60;
      const roundedHrs = Math.floor(durationInHrs);
      return [roundedHrs, t('units.hour.short'), roundedMinutes, t('units.minute.short')].join(' ');
    }
    return [roundedDuration, t('units.minute.short')].join(' ');
  };
}
