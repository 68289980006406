export interface ResourceType {
  name: {
    [key: string]: string;
    en: string;
    es: string;
    fr: string;
  };
  slug: string;
}

// To allows for the locale files to be lazy-loaded by `vue-i18n`, resource type names are hard
// coded here and not imported from `src/localization/{locale}.ts` message files.
export const resourceTypes: ResourceType[] = [
  {
    slug: 'collection',
    name: {
      en: 'Collection',
      es: 'Colección',
      fr: 'Collection',
    },
  },
  {
    slug: 'document',
    name: {
      en: 'Document',
      es: 'Documento',
      fr: 'Document',
    },
  },
  {
    slug: 'module',
    name: {
      en: 'E-Learning',
      es: 'Aprendizaje virtual',
      fr: 'Apprentissage en ligne',
    },
  },
  {
    slug: 'video',
    name: {
      en: 'Video',
      es: 'Video',
      fr: 'Vidéo',
    },
  },
];
