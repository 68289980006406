<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large' | 'xlarge' | 'huge';
    variant?: 'primary' | 'ghost' | 'text' | 'circle';
    fullWidth?: boolean;
    prependIcon?: Component | string | null;
    appendIcon?: Component | string | null;
    to?: RouteLocationRaw | null;
  }>(),
  {
    disabled: false,
    size: 'medium',
    variant: 'primary',
    fullWidth: false,
    prependIcon: null,
    appendIcon: null,
    to: null,
  }
);

const sizeClass = computed(() => {
  switch (props.size) {
    case 'small':
      return 'btn-small';
    case 'large':
      return 'btn-large';
    case 'xlarge':
      return 'btn-xlarge';
    case 'huge':
      return 'btn-huge';
    default:
      return 'btn-medium';
  }
});

const variantClass = computed(() => {
  switch (props.variant) {
    case 'ghost':
      return 'btn-ghost';
    case 'text':
      return 'btn-text';
    case 'circle':
      return 'btn-circle';
    default:
      return 'btn-primary';
  }
});
</script>

<template>
  <button
    v-if="!to"
    class="btn"
    :class="[sizeClass, variantClass, fullWidth && 'w-full', disabled && 'disabled']"
    :disabled="disabled"
  >
    <template v-if="prependIcon">
      <component :is="prependIcon" class="btn__icon mr-2 stroke-2" />
    </template>

    <slot />

    <template v-if="appendIcon">
      <component :is="appendIcon" class="btn__icon ml-2 mr-0 stroke-2" />
    </template>
  </button>
  <NuxtLinkLocale
    v-else
    class="btn"
    :class="[sizeClass, variantClass, fullWidth && 'w-full', disabled && 'disabled']"
    :to="to"
    :disabled="disabled"
  >
    <template v-if="prependIcon">
      <component :is="prependIcon" class="btn__icon mr-2" />
    </template>

    <slot />

    <template v-if="appendIcon">
      <component :is="appendIcon" class="btn__icon ml-2" />
    </template>
  </NuxtLinkLocale>
</template>
