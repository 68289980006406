<script setup lang="ts">
import { RectangleStackIcon, DocumentIcon, AcademicCapIcon, FilmIcon } from '@heroicons/vue/24/outline';
import { PlayCircleIcon } from '@heroicons/vue/24/solid';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import type { ResourceType } from '~/data/resourceTypes';

const props = withDefaults(
  defineProps<{
    description?: string;
    durationInMinutes?: number;
    isCollectionPage?: boolean;
    isFeaturedOnResourcePage?: boolean;
    isGalleryView?: boolean;
    resourceTitle: string;
    resourceType: ResourceType;
    showDuration?: boolean;
    image?: SanityImageObject | null;
  }>(),
  {
    description: '',
    durationInMinutes: 0,
    isCollectionPage: false,
    isFeaturedOnResourcePage: false,
    isGalleryView: false,
    showDuration: false,
    image: null,
  }
);

const { locale } = useI18n();
const { $urlFor } = useNuxtApp();
const formatDuration = useFormatDuration();

const localeCode = computed(() => {
  // TODO: Improve flexibility of locale code typing
  return locale.value as 'es' | 'en' | 'fr';
});

const formattedDuration = computed(() => formatDuration(props.durationInMinutes));

const icon = computed(() => {
  switch (props.resourceType.slug) {
    case 'collection':
      return RectangleStackIcon;
    case 'document':
      return DocumentIcon;
    case 'module':
      return AcademicCapIcon;
    case 'video':
      return FilmIcon;
    default:
      // TODO: Track error here: This code should never be reached
      return DocumentIcon;
  }
});

const imageUrl = computed(() => {
  return props.image ? $urlFor(props.image).maxHeight(512).url() : '';
});
</script>

<template>
  <div
    :style="
      resourceType.slug === 'collection' && image
        ? `background-image:linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${imageUrl});`
        : ''
    "
    :class="{
      'bg-cover bg-center': imageUrl && resourceType.slug === 'collection',
      'bg-grey-light p-4': imageUrl && resourceType.slug !== 'collection',
      'flex items-center justify-center bg-grey-light': !imageUrl,
      'h-56 md:h-64 lg:h-72 lg:w-72': isGalleryView,
      'hidden h-28 w-28 xs:flex sm:h-48 sm:w-48 lg:h-60 lg:w-60':
        !isGalleryView && !isFeaturedOnResourcePage && !isCollectionPage,
      'h-68 w-full p-8 md:h-96 lg:h-128': isFeaturedOnResourcePage,
      'mb-6 h-64 w-full md:mb-8 lg:h-76': isCollectionPage,
    }"
    class="resource-preview-image relative flex flex-shrink-0 items-center justify-center overflow-hidden border"
  >
    <!-- FIXME: Get image caption from Sanity and attach to image; also add for screen readers -->
    <template v-if="isGalleryView">
      <Chip class="absolute left-0 top-0 z-10" :fill="resourceType.slug === 'collection' ? 'outline' : 'solid'">
        {{ resourceType.name[localeCode] }}
      </Chip>
    </template>

    <!-- Framed image -->
    <template v-if="resourceType.slug !== 'collection' && imageUrl">
      <div
        v-if="['video'].includes(resourceType.slug)"
        class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center text-white text-opacity-70"
      >
        <PlayCircleIcon class="h-24 w-24 fill-current" />
      </div>

      <!-- Duration indicator -->
      <div
        v-if="['module', 'video'].includes(resourceType.slug) && durationInMinutes && showDuration"
        class="absolute bottom-0 right-0 z-10"
      >
        <Chip class="m-2" fill="dark" width="narrow" text-case="normal">{{ formattedDuration }}</Chip>
      </div>

      <!-- Responsive default image -->
      <!-- TODO: Find a way to add an overlay to the image. Objectively positioned divs don't work (when img is inside an intermediary div, no longer vertically resizes properly -->
      <PreviewImage
        :src="imageUrl"
        :alt="description"
        :frame="isFeaturedOnResourcePage ? 'lg' : 'md'"
        shadow="lg"
        class="relative max-h-full max-w-full"
      />
    </template>
    <!-- No image available, so show a placeholder icon -->
    <div v-if="!imageUrl" class="-mt-2.5 flex flex-col items-center justify-center text-gray-400">
      <component :is="icon" class="icon--placeholder" />
      <p class="h2--label mt-1 sm:mt-4">{{ resourceType.name[localeCode] }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.resource-preview-image:hover .action-overlay {
  opacity: 1;
}
</style>
